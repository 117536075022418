import React from 'react'
import CaretDownIcon from '@material-ui/icons/ArrowDropDown'
import { Typography, makeStyles, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import {
  Wrapper,
  Button as RAMenuButton,
  Menu,
  MenuItem,
  openMenu,
  closeMenu,
} from 'react-aria-menubutton'

import NavMenuItem from '../NavMenuItem'
import DynamicLink from '../../DynamicLink'

const useStyles = makeStyles((theme) => ({
  linkButton: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    boxSizing: 'border-box',

    height: '48px',
    width: 'auto',
    padding: 5,

    cursor: 'pointer',
    borderRadius: 5,

    textTransform: 'none',
    textDecoration: 'none',

    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'none',
    },

    '&:focus': {
      border: '2px solid #2FA43B',
      outline: 'none',
    },

    [theme.breakpoints.up('desktop')]: {
      padding: 12,
    },
  },
  caretIcon: {
    marginLeft: 5,

    color: 'black',
  },
  linkLabel: {
    boxSizing: 'border-box',
    color: 'black',

    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    fontSize: 14,
    '&:hover': {
      color: '#2FA43B',
    },
  },
  labelActive: {
    color: '#2FA43B',
  },
  buttonImg: {
    height: 40,
    width: 150,

    cursor: 'pointer',
  },

  /**
   * Menu Section
   *======================================== */
  wrapper: {
    position: 'relative',
  },
  menu: {
    position: 'absolute',

    width: 370,
    left: 'calc(50% - 160px)',
  },
  menuSpacer: {
    height: 8,
    background: 'transparent',
  },
  menuContent: {
    backgroundColor: 'white',
    boxShadow: '0px 0px 10px rgba(106, 106, 106, 0.25)',
    borderRadius: 10,

    padding: 10,
  },
  menuItem: {
    margin: '5px 0px',
  },
  dropDownButton: {
    paddingLeft: 18,
  },
}))

/**
 * Navigation header button component which supports an optional dropdown menu if the link prop contains a
 * "dropdown" key
 */
export default function DropdownButton({
  link,
  matchesDropdown,
  currentPath,
  isActive,
  onClickDropdownLink,
}) {
  const classes = useStyles()

  return link.dropdown ? (
    <Wrapper
      id={`wrapper_${link.name}`}
      onMouseEnter={() => {
        openMenu(`wrapper_${link.name}`, { focusMenu: false })
      }}
      onMouseLeave={() => {
        closeMenu(`wrapper_${link.name}`)
      }}
      // For tab/keyboard navigation
      onSelection={(value) => {
        onClickDropdownLink(value)
      }}
    >
      <div className={classes.wrapper}>
        <RAMenuButton
          tabIndex={-1} // Outer button unfocusable
        >
          <Button
            className={`${classes.linkButton} ${classes.dropDownButton}`}
            disableRipple
            onMouseDown={(event) => {
              event.preventDefault() // Stop the focus effect
            }}
          >
            <Typography
              component='span'
              variant='h6'
              key={link.name}
              className={`${classes.linkLabel} ${
                matchesDropdown ? classes.labelActive : ''
              }`}
            >
              {link.name}
            </Typography>
            <CaretDownIcon
              className={`${classes.caretIcon} ${
                matchesDropdown ? classes.labelActive : ''
              }`}
            />
          </Button>
        </RAMenuButton>
        <Menu className={classes.menu}>
          <div className={classes.menuSpacer} />
          <div className={classes.menuContent}>
            {link.dropdown.map((item) => {
              const matchesRoute = currentPath === item.route
              return (
                <MenuItem
                  value={item.route}
                  className={classes.menuItem}
                  key={item.route}
                  onMouseDown={(event) => {
                    event.preventDefault()
                  }}
                >
                  <NavMenuItem
                    item={item}
                    matchesRoute={matchesRoute}
                    target={item.target}
                    rel={item.rel}
                    comingSoon={item.comingSoon}
                  />
                </MenuItem>
              )
            })}
          </div>
        </Menu>
      </div>
    </Wrapper>
  ) : (
    <DynamicLink
      href={link.route}
      className={`${classes.linkButton}`}
      target={link.target}
      rel={link.rel}
    >
      <Typography
        className={`${classes.linkLabel} ${
          isActive ? classes.labelActive : ''
        }`}
        component='span'
        variant='h6'
        key={link.name}
      >
        {link.name}
      </Typography>
    </DynamicLink>
  )
}

DropdownButton.propTypes = {
  link: PropTypes.shape({
    name: PropTypes.string,
    route: PropTypes.string,
    rel: PropTypes.string,
    target: PropTypes.string,
    dropdown: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          description: PropTypes.string,
          route: PropTypes.string,
          rel: PropTypes.string,
          target: PropTypes.string,
        }),
      ),
      PropTypes.bool,
    ]),
  }).isRequired,

  /**
   * If true, then link.dropdown array is truthy and some dropdownItem.route matches the current active route
   */
  matchesDropdown: PropTypes.bool,

  /**
   * If true, the link.route matches the current active route
   */
  isActive: PropTypes.bool,

  /**
   * The current active route path (from react-route-dom routeMatch hook)
   */
  currentPath: PropTypes.string.isRequired,

  /**
   * Callback fired when a dropdown item is clicked (used by onSelection in menu)
   */
  onClickDropdownLink: PropTypes.func.isRequired,
}

DropdownButton.defaultProps = {
  matchesDropdown: false,
  isActive: false,
}

import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import DynamicLink from './DynamicLink'

const useStyles = makeStyles((theme) => ({
  button: {
    height: '50px',
    width: '160px',
    padding: '15px 20px',

    borderRadius: '5px',
    border: (props) =>
      props.variant === 'fill' ? 'none' : '1px solid rgba(18, 17, 39, 0.12)',
    background: (props) =>
      props.variant === 'fill' ? theme.palette.primary.main : '#fff',
    color: (props) => (props.variant === 'fill' ? '#fff' : '#000'),
    // filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))',
    cursor: 'pointer',

    fontFamily: 'Poppins,sans-serif',
    fontWeight: 600,
    fontSize: 12,
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'center',

    '&:hover': {
      background: (props) =>
        props.variant === 'fill' ? theme.palette.primary.main : '#fff',
      textDecoration: 'none',
      // filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.75))',
    },

    [theme.breakpoints.up('tablet')]: {
      height: 'calc(40px + 0.8vw)',
      width: 'calc(100px + 0.9vw)',
      // fontSize: 14,
      padding: 'calc(10px + 0.5vw) calc(10px + 0.5vw)',
    },
    [theme.breakpoints.up('desktop')]: {
      height: '60px',
      width: '180px',
      fontSize: 16,
      padding: '20px 35px',
    },
  },
}))

export default function CTALink({
  children,
  href,
  disabled,
  className,
  variant,
  target,
  rel,
}) {
  const classes = useStyles({ variant, disabled })
  return (
    <DynamicLink
      disabled={disabled}
      className={`${classes.button} ${className}`}
      href={href}
      target={target}
      rel={rel}
    >
      {children}
    </DynamicLink>
  )
}

CTALink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['fill', 'outline']),
  target: PropTypes.string,
  rel: PropTypes.string,
}

CTALink.defaultProps = {
  disabled: false,
  className: '',
  variant: 'fill',
  target: '_self',
  rel: '',
}

import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import DynamicLink from '../DynamicLink'
import ComingSoonBanner from '../../assets/shared/coming_soon_banner.svg'

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    justifyContent: 'flex-start',

    padding: '12px 15px',
    width: '100%',
    margin: 5,

    borderRadius: 5,
    backgroundColor: 'white',
    cursor: 'pointer',

    textTransform: 'none',
    textAlign: 'left',
    textDecoration: 'none',

    '&:hover': {
      boxShadow: '0px 0px 4px rgba(21, 21, 21, 0.25)',
      backgroundColor: 'white',
      textDecoration: 'none',

      '& p': {
        color: 'black',
      },

      '& h4': {
        color: 'black',
      },

      '& svg': {
        color: 'rgba(47, 164, 59, 1)',
        filter:
          'brightness(0) saturate(100%) invert(44%) sepia(72%) saturate(537%) hue-rotate(76deg) brightness(102%) contrast(79%)',
      },
    },
  },
  itemActive: {
    backgroundColor: 'rgba(240, 244, 248, 1)',
  },
  title: {
    color: '#5C5C5C',

    fontFamily: 'Poppins,sans-serif',
    fontWeight: 600,
    fontSize: 12,
  },
  description: {
    color: '#737373',

    fontFamily: 'Poppins,sans-serif',
    fontWeight: 400,
    fontSize: 10,

    [theme.breakpoints.up('desktop')]: {
      fontSize: 12,
    },
  },
  activeColor: {
    color: 'black',
  },
  iconWrapper: {
    width: 40,
    display: 'flex',
    justifyContent: 'center',

    '& svg': {
      marginRight: 10,
      // width: 'auto',
      // height: 25,
      color: 'rgba(122, 122, 122, 1)',
      filter:
        'brightness(0) saturate(100%) invert(49%) sepia(12%) saturate(7%) hue-rotate(322deg) brightness(94%) contrast(86%)',
    },
  },
  iconActiveWrapper: {
    '& svg': {
      color: 'rgba(47, 164, 59, 1)',
      filter:
        'brightness(0) saturate(100%) invert(44%) sepia(72%) saturate(537%) hue-rotate(76deg) brightness(102%) contrast(79%)',
    },
  },
  containerRight: {
    marginTop: 2,
  },

  containerRightNoDescription: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },

  comingSoonBanner: {
    width: 85,
    height: 20,
    marginLeft: 15,
  },

  titleRow: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default function NavMenuItem({
  item,
  matchesRoute,
  className,
  comingSoon,
  ...rest // Allow parent 'Menu' to pass in keyboard listener props
}) {
  const classes = useStyles()
  return (
    <DynamicLink
      href={item.route}
      className={`${classes.item} ${
        matchesRoute ? classes.itemActive : ''
      } ${className}`}
      target={item.target}
      rel={item.rel}
      // All prop spreading to enable the keyboard navigation
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <div
        className={`${classes.iconWrapper} ${
          matchesRoute ? classes.iconActiveWrapper : ''
        }`}
      >
        {item.icon}
      </div>
      {item.description ? (
        <div className={classes.containerRight}>
          <div className={classes.titleRow}>
            <Typography
              variant='h4'
              className={`${classes.title} ${
                matchesRoute ? classes.activeColor : ''
              }`}
            >
              {item.name}
            </Typography>
            {comingSoon && (
              <ComingSoonBanner className={classes.comingSoonBanner} />
            )}
          </div>
          <Typography
            variant='body2'
            className={`${classes.description} ${
              matchesRoute ? classes.activeColor : ''
            }`}
          >
            {item.description}
          </Typography>
        </div>
      ) : (
        <div className={classes.containerRightNoDescription}>
          <div className={classes.titleRow}>
            <Typography
              variant='h4'
              className={`${classes.title} ${
                matchesRoute ? classes.activeColor : ''
              }`}
            >
              {item.name}
            </Typography>
            {comingSoon && (
              <ComingSoonBanner className={classes.comingSoonBanner} />
            )}
          </div>
          <Typography
            variant='body2'
            className={`${classes.description} ${
              matchesRoute ? classes.activeColor : ''
            }`}
          >
            {item.description}
          </Typography>
        </div>
      )}
    </DynamicLink>
  )
}

NavMenuItem.propTypes = {
  /**
   * Array of items to be in the menu
   */
  item: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    route: PropTypes.string,
    icon: PropTypes.node,
    rel: PropTypes.string,
    target: PropTypes.string,
  }).isRequired,

  /**
   * If true, the current active route matches this item's route
   */
  matchesRoute: PropTypes.bool.isRequired,

  /**
   * Additional string classname to apply
   */
  className: PropTypes.string,

  comingSoon: PropTypes.bool,
}

NavMenuItem.defaultProps = {
  className: '',
  comingSoon: false,
}

import React from 'react'
import { Link as MuiLink } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import routes from '../routes'

/**
 * Link component that dynamically switches between a gatsby link component for internal routes and a MUI Link for external urls
 */

export default function DynamicLink({
  children,
  href,
  disabled,
  className,
  target,
  rel,
}) {
  const isInternalRoute = Object.values(routes).includes(href)
  return (
    <>
      {isInternalRoute ? (
        <Link
          onMouseDown={(event) => {
            event.preventDefault()
          }}
          disabled={disabled}
          className={`${className}`}
          to={href}
          href={href}
        >
          {children}
        </Link>
      ) : (
        <MuiLink
          onMouseDown={(event) => {
            event.preventDefault()
          }}
          color='primary'
          disabled={disabled}
          className={`${className}`}
          href={href}
          target={target}
          rel={rel}
        >
          {children}
        </MuiLink>
      )}
    </>
  )
}

DynamicLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
}

DynamicLink.defaultProps = {
  disabled: false,
  className: '',
  target: '_self',
  rel: '',
}

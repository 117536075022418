import { createTheme } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import PoppinsRegular from '../assets/shared/fonts/Poppins-Regular.ttf'
import PoppinsMedium from '../assets/shared/fonts/Poppins-Medium.ttf'
import PoppinsSemiBold from '../assets/shared/fonts/Poppins-SemiBold.ttf'

const breaks = {
  values: {
    mobile: 375,
    tablet: 600,
    tabletV2: 768,
    desktop: 1024,
    desktopXL: 1440,
  },
}

const breakpoints = createBreakpoints(breaks)

/* poppins-regular - latin */
const poppins400 = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `url(${PoppinsRegular}) format('woff')`,
}
/* poppins-500 - latin */
const poppins500 = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `url(${PoppinsMedium}) format('woff')`,
}

/* poppins-600 - latin */
const poppins600 = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 600,
  src: `url(${PoppinsSemiBold}) format('woff')`,
}

export default createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [poppins400, poppins500, poppins600],
      },
    },
  },
  breakpoints: breaks,
  palette: {
    primary: {
      main: '#2FA43B',
    },
    secondary: {
      main: '#000',
    },
  },
  gutters: {
    mobile: 30,
    tablet: 57,
    desktop: 97,
  },
  typography: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 400,
    h1: {
      fontFamily: 'Poppins',
      fontSize: 18,
      fontWeight: 600,
      [breakpoints.up('tablet')]: {
        fontSize: 22,
      },
      [breakpoints.up('desktop')]: {
        fontSize: 35,
      },
    },
    h2: {
      fontFamily: 'Poppins',
      fontSize: 18,
      fontWeight: 600,

      [breakpoints.up('tablet')]: {
        fontSize: 18,
      },
      [breakpoints.up('desktop')]: {
        fontSize: 25,
      },
    },
    h3: {
      fontFamily: 'Poppins',
      fontSize: 14,
      fontWeight: 600,

      [breakpoints.up('tablet')]: {
        fontSize: 14,
      },
      [breakpoints.up('desktop')]: {
        fontSize: 18,
      },
    },
    h4: {
      fontFamily: 'Poppins',
      fontSize: 14,
      fontWeight: 600,

      [breakpoints.up('tablet')]: {
        fontSize: 14,
      },
      [breakpoints.up('desktop')]: {
        fontSize: 16,
      },
    },
    body1: {
      fontFamily: 'Poppins',
      fontSize: 12,
      fontWeight: 500,

      [breakpoints.up('tablet')]: {
        fontSize: 12,
      },
      [breakpoints.up('desktop')]: {
        fontSize: 14,
      },
    },
    body2: {
      fontFamily: 'Poppins',
      fontSize: 12,
      fontWeight: 400,

      [breakpoints.up('tablet')]: {
        fontSize: 12,
      },
      [breakpoints.up('desktop')]: {
        fontSize: 14,
      },
    },
  },
  custom: {
    maxWidth: 1600,
  },
})

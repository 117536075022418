import React, { useState } from 'react'
import { IconButton, AppBar, Toolbar, makeStyles } from '@material-ui/core'
import { navigate, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import MenuIcon from '@material-ui/icons/Menu'
import PropTypes from 'prop-types'
import routes from '../../routes'
import DropdownButton from './DropdownButton'
import HamburgerMenu from './HamburgerMenu'
// Adapted from https://medium.com/@tsubasakondo_36683/create-responsive-drawer-menu-with-react-material-ui-617a42764b69

import IDVerificationIcon from '../../assets/Nav/Icons/identity_verification.svg'
import AuthenticationIcon from '../../assets/Nav/Icons/authentication_onboarding.svg'
// import KYTIcon from '../../assets/Nav/Icons/KYT_KYA.svg'
// import CommIcon from '../../assets/Nav/Icons/secure_comm.svg'
import SanctionIcon from '../../assets/Nav/Icons/sanction_list.svg'

import AboutUsIcon from '../../assets/Nav/Icons/About_us.svg'
import ContactIcon from '../../assets/Nav/Icons/Contact.svg'
// import BlogIcon from '../../assets/Nav/Icons/Blog.svg'

// import AboutIcon from '../../assets/Nav/Icons/about.svg'
import NewsIcon from '../../assets/Nav/Icons/news.svg'

import CTALink from '../CTALink'

const immeLogo = '../../assets/shared/brand/cycurid_logo.png'
const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    maxWidth: theme.custom.maxWidth,
  },
  logo: {
    padding: '5px 0px 0px 0px',
    boxSizing: 'border-box',
    borderRadius: '5px',

    '&:focus': {
      border: '2px solid #2FA43B',
      outline: 'none',
    },
  },
  logoImage: {
    width: 104,
    height: 28,
    [theme.breakpoints.up('tablet')]: {
      width: 150,
      height: 40,
    },
  },
  appBar: {
    padding: '20px 5px 20px 5px',
    maxWidth: theme.custom.maxWidth,
    right: 'unset',
    height: 95,

    boxShadow: 'none',
    backgroundColor: 'transparent',

    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginLeft: 'auto',
    color: '#000',

    [theme.breakpoints.up('desktop')]: {
      display: 'none',
    },
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  links: {
    display: 'none',

    marginLeft: 'auto',

    [theme.breakpoints.up('desktop')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',

      width: '83%',
      maxWidth: 950,
    },

    [theme.breakpoints.up('1100')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',

      width: '50%',
      maxWidth: 950,
    },
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,

    '& a': {
      [theme.breakpoints.up('tablet')]: {
        flexWrap: 'nowrap',
        width: 100,
        height: 50,
        padding: '15px 10px',
        fontSize: 12,
      },
      [theme.breakpoints.up('1200')]: { width: 136, height: 50 },
    },
  },
  buttonContainerLogin: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    marginLeft: 7,
    marginRight: 15,
    '& a': {
      [theme.breakpoints.up('tablet')]: {
        flexWrap: 'nowrap',
        width: 100,
        height: 50,
        padding: '15px 15px',
        fontSize: 12,
      },
      [theme.breakpoints.up('1200')]: { width: 136, height: 50 },
    },
  },
}))

export default function Header({ location }) {
  const links = [
    // {
    //   name: 'Individuals',
    //   route: routes.INDIVIDUALS,
    //   dropdown: false,
    // },
    // {
    //   name: 'Businesses',
    //   route: routes.BUSINESS,
    //   dropdown: false,
    // },
    {
      name: 'Features',
      route: undefined,
      dropdown: [
        {
          name: 'Identity Verification',
          description: 'Verification Solutions that Boost Your Bottom Line',
          route: routes.IDENTITY_VERIFICATION,
          icon: <IDVerificationIcon width={25} height={30} />,
        },
        {
          name: 'Sanction List Screening',
          description: 'Setting a New Standard for Sanction List Screening',
          route: routes.SANCTION_LIST,
          icon: <SanctionIcon width={30} height={25} />,
        },
        {
          name: 'Authentication & Onboarding',
          description: 'Verification Solutions that Boost Your Bottom Line',
          route: routes.AUTH_ONBOARDING,
          icon: <AuthenticationIcon width={30} height={25} />,
        },
        // {
        //   name: 'Secure Communication Tools',
        //   description: 'Secure Communication Means Customer Confidence',
        //   route: routes.COMMUNICATION_TOOLS,
        //   icon: <CommIcon />,
        //   comingSoon: true,
        // },
      ],
    },
    {
      name: 'Industries',
      route: routes.INDUSTRIES,
      dropdown: false,
    },
    // {
    //   name: 'Pricing',
    //   route: routes.PRICING,
    //   dropdown: false,
    // },
    {
      name: 'Company',
      route: undefined,
      dropdown: [
        {
          name: 'About Us',
          // description: 'Learn more about CycurID and our Mission.',
          route: routes.ABOUT,
          icon: <AboutUsIcon width={30} height={25} />,
        },
        {
          name: 'Contact Us',
          // description: 'Learn more about CycurID and our Mission.',
          route: routes.CONTACT,
          dropdown: false,
          icon: <ContactIcon width={30} height={25} />,
        },
        // {
        //   name: 'Blog',
        //   // description: 'Learn more about CycurID and our Mission.',
        //   route: 'https://blog.cycurid.com/',
        //   dropdown: false,
        //   target: '_blank',
        //   rel: 'noopener',
        //   icon: <BlogIcon />,
        // },
        {
          name: 'News',
          // description: 'Stay up-to-date with all things regarding CycurID.',
          route: 'https://news.cycurid.com/',
          target: '_blank',
          rel: 'noopener',
          icon: <NewsIcon width={30} height={30} />,
        },
      ],
    },
    // {
    //   name: 'Contact',
    //   route: routes.CONTACT,
    //   dropdown: false,
    // },
    // {
    //   name: 'Blog',
    //   route: 'https://blog.cycurid.com/',
    //   dropdown: false,
    //   target: '_blank',
    //   rel: 'noopener',
    // },
  ]
  const classes = useStyles()
  const [isOpen, setOpen] = useState(false)
  const path = location.pathname

  const gotoLink = (link = '/') => {
    // If already on this page, scroll to top since page will not reload
    setOpen(false)
    navigate(link)
  }

  /**
   * Used by keyboard navigation in Nav list onSelection
   * @param {string} link Either internal route or external url
   */
  const onClickDropdownLink = (link) => {
    if (Object.values(routes).includes(link)) {
      gotoLink(link)
    } else if (typeof window !== `undefined`) {
      const win = window.open(link, '_blank')
      win.focus()
    }
  }

  React.useEffect(() => {
    // Close the hamburger drawer menu whenever the path/route changes
    setOpen(false)
  }, [location.pathname])

  const handleDrawerToggle = () => {
    setOpen(!isOpen)
  }

  return (
    <div className={classes.root}>
      <AppBar position='absolute' className={classes.appBar}>
        <Toolbar>
          <Link
            className={classes.logo}
            to={routes.HOME}
            onMouseDown={(event) => {
              event.preventDefault()
            }}
          >
            <StaticImage
              loading='eager'
              alt='Imme'
              src={immeLogo}
              placeholder='none'
              className={classes.logoImage}
              // width={150}
              // height={40}
            />
          </Link>
          <div className={classes.links}>
            {links.map((link) => {
              const isActive = path === link.route
              const matchesDropdown =
                link.dropdown &&
                link.dropdown.some((dropdownItem) => {
                  return path === dropdownItem.route
                })
              return (
                <DropdownButton
                  key={link.name}
                  matchesDropdown={matchesDropdown}
                  link={link}
                  currentPath={path}
                  isActive={isActive}
                  onClickDropdownLink={onClickDropdownLink}
                />
              )
            })}
            <div className={classes.buttonContainerLogin}>
              <CTALink
                href={process.env.GATSBY_DASHBOARD_LOGIN_LINK}
                target='_blank'
                rel='noopener'
                variant='no'
              >
                Login
              </CTALink>
            </div>
            <div className={classes.buttonContainer}>
              <CTALink
                href={process.env.GATSBY_DASHBOARD_SIGNUP_LINK}
                target='_blank'
                rel='noopener'
              >
                Sign Up Now
              </CTALink>
            </div>
          </div>
          <IconButton
            color='inherit'
            aria-label='Open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <HamburgerMenu
        toggleMenu={handleDrawerToggle}
        open={isOpen}
        links={links}
        currentRoute={path}
      />
      <div className={classes.content} />
    </div>
  )
}

Header.propTypes = {
  location: PropTypes.objectOf(PropTypes.string),
}

Header.defaultProps = {
  location: {},
}

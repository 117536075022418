exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-communication-tools-jsx": () => import("./../../../src/pages/communication-tools.jsx" /* webpackChunkName: "component---src-pages-communication-tools-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-features-authentication-onboarding-jsx": () => import("./../../../src/pages/features/authentication-onboarding.jsx" /* webpackChunkName: "component---src-pages-features-authentication-onboarding-jsx" */),
  "component---src-pages-features-identity-verification-jsx": () => import("./../../../src/pages/features/identity-verification.jsx" /* webpackChunkName: "component---src-pages-features-identity-verification-jsx" */),
  "component---src-pages-features-sanction-screening-jsx": () => import("./../../../src/pages/features/sanction-screening.jsx" /* webpackChunkName: "component---src-pages-features-sanction-screening-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-jsx": () => import("./../../../src/pages/industries.jsx" /* webpackChunkName: "component---src-pages-industries-jsx" */),
  "component---src-pages-kyt-kya-jsx": () => import("./../../../src/pages/kyt-kya.jsx" /* webpackChunkName: "component---src-pages-kyt-kya-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-products-business-jsx": () => import("./../../../src/pages/products/business.jsx" /* webpackChunkName: "component---src-pages-products-business-jsx" */),
  "component---src-pages-products-individuals-jsx": () => import("./../../../src/pages/products/individuals.jsx" /* webpackChunkName: "component---src-pages-products-individuals-jsx" */),
  "component---src-pages-terms-of-use-jsx": () => import("./../../../src/pages/terms-of-use.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-jsx" */)
}


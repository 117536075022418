import React from 'react'
import {
  Typography,
  makeStyles,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  withStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import CaretDownIcon from '@material-ui/icons/ArrowDropDown'

import NavMenuItem from '../NavMenuItem'
import DynamicLink from '../../DynamicLink'

const useStyles = makeStyles(() => ({
  caretIcon: {
    marginLeft: 5,
    color: 'black',
  },
  linkLabel: {
    boxSizing: 'border-box',
    color: 'black',

    textDecoration: 'none',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    fontSize: 14,

    '&:hover': {
      textDecoration: 'none',
    },
  },
  labelActive: {
    color: 'black',
    fontWeight: 600,
  },
  linkButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxSizing: 'border-box',

    height: '35px',
    width: '100%',
    margin: '15px 0px',
    padding: 5,
    paddingLeft: 7, // Extra space to account for any border on active state

    cursor: 'pointer',
    borderRadius: 0,

    textTransform: 'none',

    '&:hover': {
      backgroundColor: 'transparent', // Remove button hover effect
    },

    '&:focus': {
      backgroundColor: 'rgba(94, 94, 94, 0.2)',
      border: '1px solid #767676',
      outline: 'none',
    },
  },
  linkButtonActive: {
    borderLeft: '2px solid #2fa43b',
    paddingLeft: 5,
  },

  accordion: {},
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'white',
    marginBottom: -1,
    minHeight: 'unset',
    '&$expanded': {
      minHeight: 'unset',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles(() => ({
  root: {
    padding: 0,
    marginTop: 15,
  },
}))(MuiAccordionDetails)

export default function MenuAccordion({
  isActive,
  link,
  matchesDropdown,
  currentRoute,
}) {
  const classes = useStyles()

  return (
    <div>
      {link.dropdown ? (
        <Accordion square>
          <AccordionSummary
            expandIcon={<CaretDownIcon />}
            aria-controls='panel1d-content'
            id='panel1d-header'
            className={`${classes.linkButton} ${
              isActive || matchesDropdown ? classes.linkButtonActive : ''
            }`}
          >
            <Typography
              className={`${classes.linkLabel} ${
                isActive ? classes.labelActive : ''
              }`}
              component='span'
              variant='h6'
              key={link.name}
            >
              {link.name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {link.dropdown.map((item) => {
              const matchesRoute = currentRoute === item.route
              return (
                <NavMenuItem
                  key={item.route}
                  item={item}
                  matchesRoute={matchesRoute}
                  target={item.target}
                  rel={item.rel}
                  comingSoon={item.comingSoon}
                />
              )
            })}
          </AccordionDetails>
        </Accordion>
      ) : (
        <DynamicLink
          key={link.name}
          href={link.route}
          className={`${classes.linkButton} ${
            isActive || matchesDropdown ? classes.linkButtonActive : ''
          } ${classes.linkLabel} ${isActive ? classes.labelActive : ''}`}
          target={link.target}
          rel={link.rel}
        >
          {link.name}
        </DynamicLink>
      )}
    </div>
  )
}

MenuAccordion.propTypes = {
  /**
   * A link item object
   */
  link: PropTypes.shape({
    name: PropTypes.string,
    route: PropTypes.string,
    rel: PropTypes.string,
    target: PropTypes.string,
    dropdown: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          description: PropTypes.string,
          route: PropTypes.string,
          rel: PropTypes.string,
          target: PropTypes.string,
        }),
      ),
      PropTypes.bool,
    ]),
  }).isRequired,

  /**
   * If true, this link's immediate route matches the current active route
   */
  isActive: PropTypes.bool.isRequired,

  /**
   * The current active route
   */
  currentRoute: PropTypes.string.isRequired,

  /**
   * If true, the current active route matches some route in this menu's dropdown
   */
  matchesDropdown: PropTypes.bool.isRequired,
}

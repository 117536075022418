import React from 'react'
import { makeStyles, Hidden, Drawer, IconButton } from '@material-ui/core'
import PropTypes from 'prop-types'
import CloseIcon from '@material-ui/icons/Close'

import routes from '../../../routes'
import immeLogo from '../../../assets/shared/brand/cycurid_logo.png'
import MenuAccordion from './MenuAccordion'

import CTALink from '../../CTALink'
import DynamicLink from '../../DynamicLink'

const drawerWidth = 350
const useStyles = makeStyles((theme) => ({
  logo: {
    padding: '5px 0px 0px 0px',
    boxSizing: 'border-box',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: 'rgba(94, 94, 94, 0.25)',
      textDecoration: 'none',
    },

    '&:focus': {
      backgroundColor: 'rgba(94, 94, 94, 0.2)',
      border: '1px solid #767676',
      outline: 'none',
    },
  },
  drawer: {
    [theme.breakpoints.up('tablet')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  drawerContainer: {
    padding: '30px 25px 0px 25px',
  },
  closeMenuButton: {
    marginRight: 0,
    marginLeft: 'auto',

    color: '#000',
  },
  drawerPaper: {
    width: drawerWidth,
  },

  topRow: {
    display: 'flex',
    alignItems: 'center',

    '& img': {
      width: 106,
      height: 28,
    },

    '& svg': {
      marginLeft: 'auto',
    },
  },
  thinDivider: {
    margin: '15px 0px',
    borderBottom: '1px solid #B8B8B8',
    width: '100%',
  },
  buttonWrapper: {
    position: 'relative',
    height: '100%',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    display: 'flex',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginBottom: 10,
  },
  loginButtonBorder: {
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    height: 62,
    width: 145,
    alignItems: 'center',
    fontSize: 13,
    padding: 0,
  },
  signupbuttoncontainer: {
    height: 60,
    width: 141,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 13,
    padding: 0,
  },
}))
export default function HamburgerMenu({
  toggleMenu,
  open,
  links,
  currentRoute,
}) {
  const classes = useStyles()

  return (
    <nav className={classes.drawer}>
      <Hidden smUp implementation='css'>
        <Drawer
          variant='temporary'
          anchor='right'
          open={open}
          onClose={toggleMenu}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <div className={classes.drawerContainer}>
            <div className={classes.topRow}>
              <DynamicLink className={classes.logo} href={routes.HOME}>
                <img alt='Imme' src={immeLogo} />
              </DynamicLink>

              <IconButton
                onClick={toggleMenu}
                className={classes.closeMenuButton}
              >
                <CloseIcon />
              </IconButton>
            </div>
            {links.map((link, index) => {
              const isActive = currentRoute === link.route
              const matchesDropdown =
                link.dropdown &&
                link.dropdown.some((dropdownItem) => {
                  return currentRoute === dropdownItem.route
                })
              return (
                <React.Fragment key={link.name}>
                  <MenuAccordion
                    isActive={isActive}
                    matchesDropdown={matchesDropdown}
                    link={link}
                    currentRoute={currentRoute}
                  />
                  {index < links.length - 1 && (
                    <div className={classes.thinDivider} />
                  )}
                </React.Fragment>
              )
            })}
          </div>
          <div className={classes.buttonWrapper}>
            <div className={classes.buttonContainer}>
              <CTALink
                href={process.env.GATSBY_DASHBOARD_SIGNUP_LINK}
                target='_blank'
                rel='noopener'
                className={classes.signupbuttoncontainer}
              >
                Sign Up Now
              </CTALink>
              <CTALink
                href={process.env.GATSBY_DASHBOARD_LOGIN_LINK}
                target='_blank'
                rel='noopener'
                variant='no'
                className={classes.loginButtonBorder}
              >
                Login
              </CTALink>
            </div>
          </div>
        </Drawer>
      </Hidden>
    </nav>
  )
}

HamburgerMenu.propTypes = {
  /**
   * Callback fired to toggle the menu open state
   */
  toggleMenu: PropTypes.func.isRequired,

  /**
   * If true, the menu is open and visible
   */
  open: PropTypes.bool.isRequired,

  /**
   * Array of link objects to display in the menu
   */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      route: PropTypes.string,
      rel: PropTypes.string,
      target: PropTypes.string,
      dropdown: PropTypes.oneOfType([
        PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            description: PropTypes.string,
            route: PropTypes.string,
            rel: PropTypes.string,
            target: PropTypes.string,
          }),
        ),
        PropTypes.bool,
      ]),
    }).isRequired,
  ).isRequired,

  /**
   * The current active route path
   */
  currentRoute: PropTypes.string.isRequired,
}

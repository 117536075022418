import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Fade } from 'react-awesome-reveal'
import Header from '../components/Header'
import Footer from '../components/Footer'
import routes from '../routes'
import CustomHelmet from '../components/Helmet'

const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    maxWidth: (props) => (props.useMaxWidth ? theme.custom.maxWidth : 'unset'),
    margin: 'auto',
  },
  page: {
    backgroundColor: 'white',
  },

  footer: {
    [theme.breakpoints.up('tablet')]: {
      marginTop: 350,
    },
  },
}))
const maxWidthRoutes = [
  routes.INDIVIDUALS,
  routes.IDENTITY_VERIFICATION,
  routes.COMMUNICATION_TOOLS,
  routes.ABOUT,
  routes.CONTACT,
  routes.TERMS,
  routes.PRIVACY,
]
const animatedRoutes = [routes.HOME, routes.INDIVIDUALS]

export default function PageWrapper({ children, location }) {
  const useMaxWidth = maxWidthRoutes.includes(location.pathname)
  const useAnimation = animatedRoutes.includes(location.pathname)
  const classes = useStyles({ useMaxWidth })

  return (
    <>
      <CustomHelmet location={location} />
      <div className={classes.page}>
        <div className={classes.pageWrapper}>
          <Header location={location} />
          {children}
          {useAnimation ? (
            <Fade triggerOnce fraction={0.3}>
              <Footer className={classes.footer} />
            </Fade>
          ) : (
            <Footer className={classes.footer} />
          )}
        </div>
      </div>
    </>
  )
}

PageWrapper.propTypes = {
  location: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
}

PageWrapper.defaultProps = {
  location: {},
}

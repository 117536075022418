import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import defaultTheme from '../../src/contexts/defaultTheme'
import ErrorBoundary from './components/ErrorBoundary'

export default function TopLayout({ children }) {
  return (
    <>
      <HelmetProvider>
        <ThemeProvider theme={defaultTheme}>
          <CssBaseline />
          <ErrorBoundary>{children}</ErrorBoundary>
        </ThemeProvider>
      </HelmetProvider>
    </>
  )
}

import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import routes from '../../routes'
import headTags from './headTags'
// import { graphql } from 'gatsby'

export default function CustomHelmet({ location }) {
  const { pathname } = location

  const routeKey = Object.keys(routes).find((key) => routes[key] === pathname)
  const headTag = routeKey
    ? headTags[routeKey]
    : {
        title: 'CycurID',
        description:
          'CycurID enables Digital Identity Management solutions for all companies and provideing flexible, customizable and simple to use digital identity, privacy, and security tools',
      }

  return (
    <Helmet>
      <html lang='en' />
      <title>{headTag.title}</title>
      <meta name='description' content={headTag.description} />
      <link rel='preconnect' href='https://fonts.googleapis.com' />
      <link
        rel='preconnect'
        href='https://fonts.gstatic.com'
        crossOrigin='anonymous'
      />
      <link
        rel='preconnect'
        href='https://fonts.gstatic.com'
        crossOrigin='anonymous'
      />
      <link
        href='https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap'
        rel='stylesheet'
      />
    </Helmet>
  )
}

CustomHelmet.propTypes = {
  location: PropTypes.objectOf(PropTypes.string),
}

CustomHelmet.defaultProps = {
  location: {},
}

import React from 'react'
import { Typography } from '@material-ui/core'
import { Helmet } from 'react-helmet-async'
import './ErrorBoundary.css'

import immeLogo from '../assets/cycurid_logo.png'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo)
  }

  render() {
    const { hasError } = this.state
    const { classes } = this.props
    if (hasError) {
      return (
        <>
          <Helmet>
            <title>Unexpected Error</title>
            <meta name='description' content='An unexpected error occurred' />
          </Helmet>
          <header className='errorBoundaryHeader'>
            <a className='.logo' href='/'>
              <img alt='Imme' src={immeLogo} width={150} height={40} />
            </a>
          </header>
          <main>
            <section className='errorBoundaryHero'>
              <div className='errorBoundaryHeroContent'>
                <Typography variant='h1' className='errorBoundaryHeroTitle'>
                  Something went wrong...
                </Typography>

                <Typography
                  variant='body1'
                  className='errorBoundaryHeroDescription'
                >
                  Uh Oh! An unexpected error occurred!{'\n'}Click the button
                  below to refresh the page
                </Typography>
              </div>
              <div className='errorBoundaryContainer'>
                <button
                  className='errorBoundaryButton'
                  type='button'
                  onClick={() => {
                    if (window) {
                      location.reload()
                    }
                  }}
                >
                  Reload
                </button>
              </div>
            </section>
          </main>
        </>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary

import React from 'react'
import { makeStyles, Typography, Link as MuiLink } from '@material-ui/core'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import CycurIDLogo from '../assets/shared/brand/cycurid_logo.png'
import MediumLogo from '../assets/shared/CycurID Socials/medium_logo.png'
import LinkedInLogo from '../assets/shared/CycurID Socials/linkedin_logo2.png'
import TwitterLogo from '../assets/shared/CycurID Socials/twitter_logo2.png'
// import FacebookLogo from '../assets/shared/Imme Socials/facebook_logo.png'
// import InstagramLogo from '../assets/shared/Imme Socials/instagram_logo.png'
import routes from '../routes'

const useStyles = makeStyles((theme) => ({
  footer: {
    paddingBottom: 40,
    margin: 'auto',
    width: '95%',
    maxWidth: '1600px',
  },
  container: {
    display: 'grid',
    gridTemplate: '1fr 1fr 1fr / 3fr 1.15fr',
    // justifyItems: 'center',
    alignItems: 'center',
    margin: 'auto',
    rowGap: 30,
    maxWidth: '100%',
    '& :nth-child(1)': {
      order: 1,
    },
    '& :nth-child(2)': {
      order: 2,
    },
    '& :nth-child(3)': {
      order: 4,
    },
    '& :nth-child(4)': {
      order: 3,
    },
    '& :nth-child(5)': {
      order: 5,
    },
    '& :nth-child(6)': {
      order: 6,
      // marginTop: -30,
    },
    '& :nth-child(7)': {
      order: 9,
    },
    '& :nth-child(8)': {
      order: 8,
      marginTop: 30,
    },
    // '& :nth-child(9)': {
    //   order: 9,
    // },
    [theme.breakpoints.up('tablet')]: {
      gridTemplate: '1fr 1fr / 1fr 1fr 1fr 1fr',
      // justifyItems: 'center',
      alignItems: 'start',
      columnGap: 20,
      rowGap: 10,
      margin: '0px auto 50px auto',
      maxWidth: theme.custom.maxWidth,
      '& :nth-child(1)': {
        order: 1,
      },
      '& :nth-child(2)': {
        order: 2,
      },
      '& :nth-child(3)': {
        order: 3,
      },
      '& :nth-child(4)': {
        order: 6,
      },
      '& :nth-child(5)': {
        order: 5,
      },
      '& :nth-child(6)': {
        order: 4,
        paddingLeft: 50,
        marginTop: 0,
      },
      '& :nth-child(7)': {
        order: 7,
      },
      '& :nth-child(8)': {
        order: 8,
      },
      '& :nth-child(9)': {
        order: 9,
      },
    },

    [theme.breakpoints.up('desktop')]: {
      gridTemplate: '1fr / 1fr 1fr 1fr 1fr 1fr 0fr',
      justifyItems: 'center',
      alignItems: 'start',
      columnGap: 20,
      rowGap: 0,
      margin: '0px auto 100px auto',
      maxWidth: 1260,
      '& :nth-child(1)': {
        order: 1,
      },
      '& :nth-child(2)': {
        order: 3,
      },
      '& :nth-child(3)': {
        order: 4,
      },
      '& :nth-child(4)': {
        order: 5,
      },
      '& :nth-child(5)': {
        order: 6,
      },
      '& :nth-child(6)': {
        order: 2,
        position: 'relative',
        right: 50,
      },
      '& :nth-child(7)': {
        order: 7,
      },
      '& :nth-child(8)': {
        order: 8,
      },
      '& :nth-child(9)': {
        order: 9,
      },
    },
  },
  logo: {
      width: 100,
      height: 28,
      marginBottom: 10,

      [theme.breakpoints.up('desktop')]: {
        width: 109,
        height: 'auto',
      },
  },
  titleBlock: {
    display: 'flex',
    gridColumn: 'span 2',
    alignSelf: 'end',
    flexDirection: 'column',
    alignItems: 'center',

    // maxWidth: 250,

    [theme.breakpoints.up('tablet')]: {
      gridColumn: 'span 1',
      alignSelf: 'start',
      alignItems: 'flex-start',

      textAlign: 'start',
    },

    [theme.breakpoints.up('desktop')]: {
      gridColumn: 'span 1',
      alignSelf: 'start',
      alignItems: 'flex-start',

      textAlign: 'start',
    },

    // '& img': {
    //   width: 100,
    //   height: 28,
    //   marginBottom: 10,

    //   [theme.breakpoints.up('desktop')]: {
    //     width: 109,
    //     height: 'auto',
    //   },
    // },
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    '& :nth-child(3)': {
      order: 2,
    },

    [theme.breakpoints.up('desktop')]: {
      alignItems: 'flex-start',
      '& :nth-child(3)': {
        order: 3,
      },
    },
  },
  link: {
    color: 'black',
    fontSize: '12px',
    marginTop: 10,
    textDecoration: 'none',

    [theme.breakpoints.up('desktop')]: {
      fontSize: '14px',
    },
  },
  linkContainerInf: {
    width:'10px',
  },
  socialLinkContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    marginTop: 10,

    '& :nth-child(2)': {
      marginRight: 15,
      marginLeft: 15,
    },
    [theme.breakpoints.up(600)]: {
      // marginTop: 'calc(-100px + 0.5vw)',
      position: 'absolute',
      left: '3%',
      marginTop: '-90px',
      '& :nth-child(2)': {
        marginRight: 12,
        marginLeft: 12,
      },
      // bottom: '16.5vh',
    },
    [theme.breakpoints.up(645)]: {
      position: 'absolute',
      left: '3%',
      marginTop: '-90px',
    },
    [theme.breakpoints.up(804)]: {
      position: 'absolute',
      left: '3%',
      marginTop: '-60px',
    },

    [theme.breakpoints.up(1024)]: {
      display:'none',
      marginTop: -125,
      marginLeft: -5,
      position: 'static',
    },
    [theme.breakpoints.up(1186)]: {
      display:'none',
      marginTop: -105,
      marginLeft: -5,
      position: 'static',
    },
  },
  socialLinkContainer2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    display:'none',
    marginTop: 10,

    [theme.breakpoints.up(1024)]: {
      display:'block',
    },
  },
  socialIcon: {
    width: 24,
    height: 24,
    [theme.breakpoints.up('tablet')]: {
      width: 16,
      height: 16,
    },
    [theme.breakpoints.up('desktop')]: {
      width: 24,
      height: 24,
    },
  },
  socialButton: {
    paddingBottom: 5,
    borderRadius: 10,

    [theme.breakpoints.up(1024)]: {
      padding:5,
    },
  },
  emptyColumn: {
    display: 'none',

    [theme.breakpoints.up('desktop')]: {
      display: 'block',
    },
  },
  emptyColumn2: {
    display: 'none',
    [theme.breakpoints.up('tablet')]: {
      display: 'block',
    },
    [theme.breakpoints.up('desktop')]: {
      display: 'none',
    },
  },
  emptyColumn3: {
    display: 'block',
    visibility: 'hidden',
    [theme.breakpoints.up('tablet')]: {
      display: 'none',
      visibility: 'visible',
    },
    [theme.breakpoints.up('desktop')]: {
      display: 'none',
    },
  },
  verticalBar: {
    fontWeight: 500,
    padding: '0px 10px',
    fontSize: '12px',
    lineHeight: '18px',
    textAlign: 'center',
    [theme.breakpoints.up('tablet')]: {
      padding: '0px 15px',
    },
  },
  copyrightContainer: {
    [theme.breakpoints.up('desktop')]: {
      maxWidth: 1128,
      width: '90%',
      margin: '0 auto',
    },
  },
  copyright: {
    textAlign: 'center',
    color: '#000000',
    display: 'none',
    [theme.breakpoints.up('tablet')]: {
      display: 'inline-block',
      textAlign: 'start',
    },
    [theme.breakpoints.up('desktop')]: {
      textAlign: 'start',
      color: 'rgba(18, 17, 39, 0.56)',
      fontWeight: 400,
    },
    [theme.breakpoints.up('desktopXL')]: {},
  },
  copyright2: {
    textAlign: 'center',
    color: '#000000',
    marginTop: 25,
    [theme.breakpoints.up('tablet')]: {
      display: 'none',
    },
  },
  terms: {
    position: 'relative',
    top: -83,
    [theme.breakpoints.up('tablet')]: {
      position: 'static',
    },
  },
  termsText: {
    [theme.breakpoints.up('desktop')]: {
      color: 'rgba(18, 17, 39, 0.56)',
    },
  },
  title: {
    [theme.breakpoints.up('desktop')]: {
      color: 'rgba(18, 17, 39, 0.4)',
      letterSpacing: '0.8px',
      textTransform: 'uppercase',
      lineHeight: '170%',
      marginBottom: 10,
    },
  },
  divider: {
    [theme.breakpoints.up('desktopXL')]: {
      marginTop: 100,
    },
  },
  copyrightAndTermsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 5,
    [theme.breakpoints.up('tablet')]: {
      justifyContent: 'space-between',
    },
  },
}))
export default function CTABox({ style, className }) {
  const classes = useStyles()

  return (
    <footer style={style} className={`${classes.footer} ${className}`}>
      <div className={`${classes.container}`}>
    
        <div className={classes.titleBlock}>
          <img className={classes.logo} src={CycurIDLogo} alt='CycurID Logo' />
          <div className={classes.socialLinkContainer2}>
            <MuiLink
              href='https://medium.com/@cycurid'
              className={classes.socialButton}
              target='_blank'
              rel='noopener'
            >
              <img
                className={classes.socialIcon}
                src={MediumLogo}
                alt='medium logo'
              />
            </MuiLink>
            <MuiLink
              href='https://www.linkedin.com/company/cycurid'
              className={classes.socialButton}
              target='_blank'
              rel='noopener'
            >
              <img
                className={classes.socialIcon}
                src={LinkedInLogo}
                alt='linkedin logo'
              />
            </MuiLink>
            <MuiLink
              href='https://twitter.com/CycurId'
              className={classes.socialButton}
              target='_blank'
              rel='noopener'
            >
              <img
                className={classes.socialIcon}
                src={TwitterLogo}
                alt='twitter logo'
              />
            </MuiLink>
          </div>
        </div>
        {/* <div className={classes.linkContainer}>
          <Typography variant='h4' component='h2' className={classes.title}>
            Our Products
          </Typography>
          <Link to={routes.BUSINESS} className={classes.link} variant='body1'>
            Businesses
          </Link>
          <Link
            to={routes.INDIVIDUALS}
            className={classes.link}
            variant='body1'
          >
            Individuals
          </Link>
          <Link
            className={classes.link}
            href='https://imme.io/'
            target='_blank'
          >
            Visit Imme
          </Link>
        </div> */}
        <div className={classes.linkContainer}>
          <Typography variant='h4' component='h2' className={classes.title}>
            Our Features Set
          </Typography>
          <Link
            className={classes.link}
            to={routes.IDENTITY_VERIFICATION}
            variant='body1'
          >
            Identity Verification
          </Link>
          <Link
            className={classes.link}
            to={routes.SANCTION_LIST}
            variant='body1'
          >
            Sanction List Screening{' '}
          </Link>
          <Link
            className={classes.link}
            to={routes.AUTH_ONBOARDING}
            variant='body1'
          >
            Authentication & Onboarding{' '}
          </Link>
        </div>
        <div className={classes.linkContainer}>
          <Typography variant='h4' component='h2' className={classes.title}>
            For Industries
          </Typography>
          <Link to={routes.INDUSTRIES} className={classes.link} variant='body1'>
            Industries
          </Link>
          {/* <Link className={classes.link} to={routes.PRICING}>
            Pricing
          </Link> */}
        </div>
        <div className={classes.emptyColumn2} />
        <div className={classes.linkContainer}>
          <Typography variant='h4' component='h2' className={classes.title}>
            Company
          </Typography>
          <Link to={routes.ABOUT} className={classes.link} variant='body1'>
            About Us
          </Link>
          <Link className={classes.link} to={routes.CONTACT}>
            Contact Us
          </Link>
          <Link
            className={classes.link}
            to='https://news.cycurid.com'
            target='_blank'
          >
            News
          </Link>
        </div>
        <div className={classes.emptyColumn} />
        <div className={classes.linkContainer}>
          {/* <Typography variant='h4' component='h2'>
            CycurID Socials
          </Typography> */}
          <div className={classes.socialLinkContainer}>
            <MuiLink
              href='https://medium.com/@cycurid'
              className={classes.socialButton}
              target='_blank'
              rel='noopener'
            >
              <img
                className={classes.socialIcon}
                src={MediumLogo}
                alt='medium logo'
              />
            </MuiLink>
            <MuiLink
              href='https://www.linkedin.com/company/cycurid'
              className={classes.socialButton}
              target='_blank'
              rel='noopener'
            >
              <img
                className={classes.socialIcon}
                src={LinkedInLogo}
                alt='linkedin logo'
              />
            </MuiLink>
            <MuiLink
              href='https://twitter.com/CycurId'
              className={classes.socialButton}
              target='_blank'
              rel='noopener'
            >
              <img
                className={classes.socialIcon}
                src={TwitterLogo}
                alt='twitter logo'
              />
            </MuiLink>
          </div>
        </div>
        <div className={classes.linkContainer}>
          {/* <Typography variant='h4' component='h2'>
            Imme Socials
          </Typography> */}

          {/* <div className={classes.socialLinkContainer} style={{ marginTop: 5 }}>
            <MuiLink
              href='https://www.facebook.com/GetYourImmeId/'
              className={classes.socialButton}
              target='_blank'
              rel='noopener'
            >
              <img
                className={classes.socialIcon}
                src={FacebookLogo}
                alt='facebook logo'
              />
            </MuiLink>
            <MuiLink
              href='https://www.instagram.com/getyourimmeid/'
              className={classes.socialButton}
              target='_blank'
              rel='noopener'
            >
              <img
                className={classes.socialIcon}
                src={InstagramLogo}
                alt='instagram logo'
              />
            </MuiLink>
            <MuiLink
              href='https://twitter.com/getyourimmeid'
              target='_blank'
              rel='noopener'
              className={classes.socialButton}
            >
              <img
                className={classes.socialIcon}
                src={TwitterLogo}
                alt='twitter logo'
              />
            </MuiLink>
          </div> */}
        </div>
      </div>
      <div className={classes.copyrightContainer}>
        <hr
          style={{ backgroundColor: '#c8c8c8', height: 1, border: 'none' }}
          className={classes.divider}
        />
        <Typography variant='body1' className={classes.copyright2}>
          © 2022 CycurID. All Rights Reserved
        </Typography>
        <div className={classes.copyrightAndTermsContainer}>
          <Typography variant='body1' className={classes.copyright}>
            © 2022 CycurID. All Rights Reserved
          </Typography>
          <div className={classes.terms}>
            <Link
              className={`${classes.link} ${classes.termsText}`}
              to={routes.TERMS}
              variant='body1'
            >
              Terms
            </Link>
            <span className={classes.verticalBar}>|</span>
            <Link
              className={`${classes.link} ${classes.termsText}`}
              to={routes.PRIVACY}
              variant='body1'
            >
              Privacy
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}
CTABox.propTypes = {
  /**
   * Additional styles to apply to the container
   */
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  /**
   * Additional classnames to apply to container
   */
  className: PropTypes.string,
}
CTABox.defaultProps = {
  style: {},
  className: '',
}

const routes = {
  HOME: '/',
  // INDIVIDUALS: '/products/individuals/',
  IDENTITY_VERIFICATION: '/features/identity-verification/',
  SANCTION_LIST: '/features/sanction-screening/',
  COMMUNICATION_TOOLS: '/communication-tools/',
  AUTH_ONBOARDING: '/features/authentication-onboarding/',
  KYT_KYA: '/kyt-kya/',
  INDUSTRIES: '/industries/',
  ABOUT: '/about/',
  CONTACT: '/contact-us/',
  TERMS: '/terms-of-use/',
  PRIVACY: '/privacy-policy/',
  // PRICING: '/pricing/',
  NEWS: '/news/',
  // BUSINESS: '/products/business/',
}
// export default routes
module.exports = routes

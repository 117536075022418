export default {
  HOME: {
    title: `CycurID: Enabling Security, Privacy and Decentralization to WEB3 Technologies`,
    description: `CycurID is a flexible, customizable, and reusable digital identity management platform providing 
an efficient solution to eKYC, AML and authenticate users stopping fraudsters from accessing your 
platform.`,
  },
  BUSINESS: {
    title: `Identity Verification, Seamless Authentication & Onboarding, Continuous KYC/AML | CycurID`,
    description: `CycurID provides identity verification, seamless authentication and biometric eKYC and AML 
solutions that meet today’s complex compliance requirements while reducing onboarding time.`,
  },
  INDIVIDUALS: {
    title: `Portable eKYC, Seamless Onboarding, Digital ID Management with the imme app | CycurID`,
    description: `Imme app by CycurID offers passwordless onboarding, continuous screening, and authentication 
without the need to divulge any personal information. Increase your security and privacy while 
reducing your liability.`,
  },
  IDENTITY_VERIFICATION: {
    title: `AI-powered Identity Verification & Liveness Detection | CycurID`,
    description: `Cycurid AI-driven platform provides fortified security, continuous automated KYC, 
management-free-data and audited verification using Zero-Knowledge Verification with Client-Side ID 
Management.`,
  },
  SANCTION_LIST: {
    title: `Apply Continuous AML Monitoring & Biometric Screening | CycurID`,
    description: `CycurID advanced AI-Driven and fully automated tools enable continuous and biometric AML 
screening and monitoring that includes Politically Exposed Persons (PEP), sanctions lists, OFCA and 
more. Keep fraud and financial crime away from your business permanently.`,
  },
  COMMUNICATION_TOOLS: {
    title: `Secure Communication Tools`,
    description: `CycurID verifies and authenticates messages sent through our communications network, 
eliminating the risk of fraudsters impersonating you or anyone else`,
  },
  AUTH_ONBOARDING: {
    title: `Biometric authentication, MFA and User Onboarding. No more usernames or passwords. | 
CycurID`,
    description: `Onboarding customers seamlessly with CycurID’s biometric multi factor authentication (MFA) 
solution with customized onboarding rules. OpenID connect and OAuth2 ensure the fastest and most 
secure experience for your users. Save time and money.`,
  },
  KYT_KYA: {
    title: `KYT and KYA`,
    description: `Understand more about how CycurID's revolutionary approach to Know Your Transaction (KYT) 
and Know Your Address (KYA) protocols removes the downsides of anonymous digital currencies while 
preserving truly anonymous commerce.`,
  },
  INDUSTRIES: {
    title: `Full suite of identity applications that fits any industry requiring user validation. | CycurID`,
    description: `CycurID provides a secure identity verification solution and KYC/AML services that benefits all 
industries. Customizable authentication onboarding criteria to ensure only targeted customer get 
onboarded.`,
  },
  ABOUT: {
    title: `About Us | CycurID`,
    description: `CycurID is actively addressing and resolving the keystone issues facing our digital society. Learn 
more about our company and mission`,
  },
  CONTACT: {
    title: `Contact Us | CycurID`,
    description: `Curious about anything CycurID? Do not hesitate to contact us with any questions`,
  },
  TERMS: {
    title: `Terms & Conditions | CycurID`,
    description: `Learn about CycurID and Immes terms and conditions that may apply when using our products 
and services`,
  },
  PRIVACY: {
    title: `Privacy Policy | CycurID`,
    description: `Learn about CycurID and Immes privacy that may apply when using our products and services`,
  },
  //   PRICING: {
  //     title: `Spend less get more with Identity Verification Services by CycurID`,
  //     description: `The CycurID pricing packages are easy to understand and extremely affordable for companies of
  // all sizes. Our solution requires a simple API integration, it’s platform agnostic.`,
  //   },
}
